import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Switch } from "@headlessui/react";
import { EmptyState } from "../components";
import ReservationModal, { CancelReservationButton } from "./Reservation";
import {
  CalendarIcon,
  CurrencyDollarIcon,
  MailIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { displayAmount, displayDate } from "../components/format";
import { Calendar } from "../components/Calendar";
import { getRoomDetails } from "../action/actionOwner";
import { LoadingContent } from "../components/Loading";
import * as net from "node:net";
import BooleanSwitch from "../components/BooleanSwitch";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Properties({
  account,
  propertyId,
  ownerId,
}: {
  account: any;
  propertyId: string;
  ownerId: string;
}) {
  const { rooms = [] } = account || {};

  const roomsToShow = rooms?.filter((room: any) => room?.status === "active");
  const firstRoomId = roomsToShow?.[0]?.roomId || "";
  const [selectedRoomId, setSelectedRoomId] = useState(firstRoomId);

  useEffect(() => {
    setSelectedRoomId(firstRoomId);
  }, [firstRoomId]);

  const room = roomsToShow.find((room: any) => room.roomId === selectedRoomId);

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-2 flex flex-col gap-y-4 md:flex-row md:justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Properties
          </h1>
          <div>
            <select
              id="selectedRoomId"
              name="selectedRoomId"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={(e) => setSelectedRoomId(e.target.value)}
              value={selectedRoomId}
            >
              {roomsToShow.map((room: any) => (
                <option value={room.roomId}>{room.label}</option>
              ))}
            </select>
          </div>
        </div>
      </header>

      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
          {!!selectedRoomId ? (
            <SingleProperty
              key={selectedRoomId}
              room={room}
              account={account}
              propertyId={propertyId}
              ownerId={ownerId}
            />
          ) : (
            <EmptyState text="No Properties Found" />
          )}
        </div>
      </main>
    </>
  );
}

// const SinglePropertyOld = ({ room, account }: { room: any; account: any }) => {
//   const {
//     portalAvailability = 0,
//     roomAvailability,
//     portalAllowReservation,
//     ownerReservations,
//     portalReservationDetails,
//   } = account || {};
//   const [isOpen, setOpen] = useState(false);
//   const { roomId, label } = room || {};
//   const reservations =
//     ownerReservations.find((item: any) => item.roomId === roomId)
//       ?.reservations || [];

//   const ownerReservationsList = reservations.filter(
//     (reservation: any) => reservation.agent === "OWNER"
//   );

//   const guestReservationsList = reservations.filter(
//     (reservation: any) => reservation.agent !== "OWNER"
//   );

//   const guestReservationsTotal = guestReservationsList.reduce(
//     (sum: number, res: any) => (sum += +res.accommodationTotal || 0),
//     0
//   );

//   return (
//     <div className="bg-white shadow overflow-hidden sm:rounded-lg">
//       <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
//         <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
//           <div className="ml-4 mt-4">
//             <h3 className="text-lg leading-6 font-medium text-gray-900">
//               {label}
//             </h3>
//             {/* <p className="mt-1 text-sm text-gray-500">
//               Lorem ipsum dolor sit amet consectetur adipisicing elit quam
//               corrupti consectetur.
//             </p> */}
//           </div>
//           <div className="ml-4 mt-4 flex-shrink-0">
//             {portalAllowReservation === "enabled" && (
//               <div className="ml-4 flex-shrink-0">
//                 <Button onClick={() => setOpen(true)}>
//                   Create Reservation
//                 </Button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
//         <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
//           <div className="sm:col-span-2">
//             <dt className="text-sm font-medium text-gray-500 mb-2">
//               Availability
//             </dt>
//             <dd className="mt-1 text-sm text-gray-900">
//               <Calendar />
//               {/* <MonthAvailability
//                 monthCount={portalAvailability}
//                 availability={roomAvailability}
//                 roomId={roomId}
//               /> */}
//             </dd>
//           </div>

//           <div className="sm:col-span-2">
//             <dt className="text-sm font-medium text-gray-500 mb-2">
//               <span>Owner Reservations</span>
//               <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
//                 {ownerReservationsList?.length}
//               </span>
//             </dt>
//             <dd className="mt-1 text-sm text-gray-900">
//               {ownerReservationsList?.length > 0 ? (
//                 <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
//                   {ownerReservationsList.map((reservation: any) => (
//                     <OwnerReservation
//                       reservation={reservation}
//                       key={reservation.id}
//                     />
//                   ))}
//                 </ul>
//               ) : (
//                 <EmptyState text="No Future Owner Reservations Found." />
//               )}
//             </dd>
//           </div>

//           {!!portalReservationDetails && (
//             <div className="sm:col-span-2">
//               <dt className="text-sm font-medium text-gray-500 mb-2">
//                 <span>Guest Reservations</span>
//                 <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
//                   {guestReservationsList?.length}
//                 </span>
//                 {portalReservationDetails === "value" && (
//                   <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
//                     Total: {displayAmount(guestReservationsTotal)}
//                   </span>
//                 )}
//               </dt>
//               <dd className="mt-1 text-sm text-gray-900">
//                 {guestReservationsList?.length > 0 ? (
//                   <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
//                     {guestReservationsList.map((reservation: any) => (
//                       <GuestReservation
//                         reservation={reservation}
//                         key={reservation.id}
//                         portalReservationDetails={portalReservationDetails}
//                       />
//                     ))}
//                   </ul>
//                 ) : (
//                   <EmptyState text="No Future Guest Reservations Found." />
//                 )}
//               </dd>
//             </div>
//           )}
//         </dl>
//       </div>
//       <ReservationModal
//         isOpen={isOpen}
//         setOpen={setOpen}
//         room={room}
//         account={account}
//       />
//     </div>
//   );
// };

const SingleProperty = ({
  room,
  account,
  propertyId,
  ownerId,
}: {
  room: any;
  account: any;
  propertyId: string;
  ownerId: string;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [view, setView] = useState("calendar");
  const [reservations, setReservations] = useState([]);
  const [availability, setAvailability] = useState({});
  const [isOpen, setOpen] = useState(false);
  const { roomId } = room || {};
  const {
    portalAvailability = 0,
    roomAvailability,
    portalAllowReservation,
    ownerReservations,
    portalReservationDetails,
  } = account || {};

  const handleReservations = async () => {
    setLoading(true);
    const result = await getRoomDetails({ propertyId, roomId, ownerId });
    setAvailability(result?.availability);
    setReservations(result?.reservations);
    setLoading(false);
  };
  useEffect(() => {
    handleReservations();
  }, [roomId]);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0">
            <nav className="flex space-x-4" aria-label="Tabs">
              <span
                className={classNames(
                  view === "calendar"
                    ? "bg-indigo-100 text-indigo-700"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                )}
                onClick={() => setView("calendar")}
              >
                Calendar
              </span>
              <span
                className={classNames(
                  view === "table"
                    ? "bg-indigo-100 text-indigo-700"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                )}
                onClick={() => setView("table")}
              >
                Table
              </span>
            </nav>
          </div>
          <div>
            {portalAllowReservation === "enabled" && (
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="focus:outline-none w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Create Reservation
              </button>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingContent />
      ) : (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          {view === "calendar" && (
            <ReservationCalendar
              reservations={reservations}
              availability={availability}
              account={account}
              setOpen={setOpen}
              roomId={roomId}
              handleReservations={handleReservations}
            />
          )}

          {view === "table" && (
            <ReservationTable reservations={reservations} account={account} />
          )}
        </div>
      )}

      <ReservationModal
        isOpen={isOpen}
        setOpen={setOpen}
        room={room}
        account={account}
        handleReservations={handleReservations}
      />
    </div>
  );
};

const OwnerReservation = ({
  reservation,
  handleReservations,
  portalAllowReservation,
}: {
  reservation: any;
  handleReservations: any;
  portalAllowReservation: boolean;
}) => {
  const {
    checkIn,
    checkOut,
    firstName,
    lastName,
    adults,
    children,
    email,
    ref,
    id,
  } = reservation;
  return (
    <li key={id}>
      <div className="pl-3 pr-4 py-4 flex items-center justify-between text-sm">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="text-sm font-medium text-indigo-600 truncate">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                  {ref}
                </span>
                {`${firstName} ${lastName}`}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <UserGroupIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">
                  {`${adults} adult${+adults > 1 ? "s" : ""}${
                    +children > 0 ? `, ${children} children` : ""
                  }`}
                </span>
              </p>
            </div>
            <div className="mt-2 md:mt-0 block">
              <div>
                <p className="flex items-center text-sm text-gray-700">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                    aria-hidden="true"
                  />
                  <span className="truncate font-bold">
                    {displayDate(checkIn)} to {displayDate(checkOut)}
                  </span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <MailIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="truncate">{email}</span>
                </p>
              </div>
            </div>
            {!!portalAllowReservation && (
              <div className="mt-2 md:mt-0 block">
                <div>
                  <CancelReservationButton
                    reservationId={id}
                    reservation={reservation}
                    handleReservations={handleReservations}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {/* <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      /> */}
        </div>
      </div>
    </li>
  );
};

const GuestReservation = ({
  reservation,
  portalReservationDetails,
}: {
  reservation: any;
  portalReservationDetails?: string;
}) => {
  const {
    checkIn,
    checkOut,
    firstName,
    lastName,
    adults,
    children,
    ref,
    id,
    accommodationTotal,
    grossAccommodationTotal,
    netAccommodationTotal,
    netAccommodationTotalAfterOwnerCharges,
    agentName,
  } = reservation;

  // const grossAmount =
  //   rebateType === "percent"
  //     ? amount / (1 - rebateAmount / 100)
  //     : rebateType === "fixed"
  //     ? amount + rebateAmount
  //     : amount;

  const initial = `${String(firstName || "")[0] || ""}${
    String(lastName || "")[0] || ""
  }`;

  return (
    <li key={id}>
      <div className="pl-3 pr-4 py-4 flex items-center justify-between text-sm">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="text-sm font-medium text-indigo-600 truncate uppercase">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                  {ref}
                </span>
                {initial}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <UserGroupIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">
                  {`${adults} adult${+adults > 1 ? "s" : ""}${
                    +children > 0 ? `, ${children} children` : ""
                  }`}
                </span>
                <span className="ml-3">
                  Booked via <strong>{agentName}</strong>
                </span>
              </p>
            </div>
            <div className="mt-2 md:mt-0 block">
              <div>
                <p className="flex items-center text-sm text-gray-700">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                    aria-hidden="true"
                  />
                  <span className="truncate font-bold">
                    {displayDate(checkIn)} to {displayDate(checkOut)}
                  </span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CurrencyDollarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {portalReservationDetails === "value" && (
                    <>
                      <span className="truncate">
                        {displayAmount(accommodationTotal)}
                      </span>
                      {grossAccommodationTotal !== accommodationTotal && (
                        <span className="text-gray-400 text-xs ml-2">
                          Gross Amount: {displayAmount(grossAccommodationTotal)}
                        </span>
                      )}
                    </>
                  )}

                  {portalReservationDetails === "netValue" && (
                    <span className="truncate">
                      {displayAmount(netAccommodationTotal)}
                    </span>
                  )}

                  {portalReservationDetails === "netValueAfterOwnerCharges" && (
                    <span className="truncate">
                      {displayAmount(netAccommodationTotalAfterOwnerCharges)}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      /> */}
        </div>
      </div>
    </li>
  );
};

const ReservationCalendar = ({
  reservations,
  availability,
  account,
  setOpen,
  roomId,
  handleReservations,
}: {
  reservations: any[];
  availability: any;
  account: any;
  setOpen: any;
  roomId: string;
  handleReservations: any;
}) => {
  const [thisMonth, setMonth] = useState(dayjs().format("YYYY-MM-DD"));
  const monthStart = dayjs(thisMonth).startOf("month").format("YYYY-MM-DD");
  const monthEnd = dayjs(thisMonth).endOf("month").format("YYYY-MM-DD");
  const monthLength = dayjs(monthEnd).diff(monthStart, "days") + 1;

  const {
    portalAvailability = 0,
    roomAvailability,
    portalAllowReservation,
    ownerReservations,
    portalReservationDetails,
  } = account || {};

  const reservationsThisMonth = reservations.filter(
    (res) => res.checkOut >= monthStart && res.checkIn <= monthEnd
  );

  const ownerReservationsList = reservationsThisMonth.filter(
    (reservation: any) => reservation.agent === "OWNER"
  );

  const guestReservationsList = reservationsThisMonth.filter(
    (reservation: any) => reservation.agent !== "OWNER"
  );

  const guestRoomNightsTotal = guestReservationsList.reduce(
    (sum: number, res: any) =>
      (sum += dayjs(res.checkOut).diff(res.checkIn, "days") || 0),
    0
  );

  const ownerRoomNightsTotal = ownerReservationsList.reduce(
    (sum: number, res: any) =>
      (sum += dayjs(res.checkOut).diff(res.checkIn, "days") || 0),
    0
  );

  const guestReservationsTotal = guestReservationsList.reduce(
    (sum: number, res: any) => (sum += +res.accommodationTotal || 0),
    0
  );

  const occupiedNights = Array(monthLength)
    .fill("a")
    .map((_, index) => {
      const date = dayjs(monthStart).add(index, "days").format("YYYY-MM-DD");
      const isOccupied = availability?.[date]?.[roomId]?.isAvailable === false;
      return isOccupied;
    })
    .filter(Boolean).length;
  const occupancy = Number((+occupiedNights / +monthLength) * 100).toFixed(2);

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-2">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-16 mt-5">
          <div className="col-span-4 mb-10 lg:mb-0">
            <Calendar
              reservations={reservationsThisMonth}
              availability={availability}
              portalAvailability={+portalAvailability}
              thisMonth={thisMonth}
              setMonth={setMonth}
              roomId={roomId}
            />
            <div className="mt-4 text-xs flex items-center flex-between font-medium">
              <div className="text-gray-700">Occ: {occupancy}%</div>
              <div className="flex items-center">
                <div className="h-4 w-4 bg-green-600 inline-block"></div>
                <span className="text-green-600 ml-1">Owner</span>
                <div className="h-4 w-4 bg-indigo-600 inline-block ml-4"></div>
                <span className="text-indigo-600 ml-1">Guest</span>
              </div>
            </div>
          </div>
          <div className="col-span-8">
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500 mb-2">
                <span>Owner Reservations</span>
                {+ownerReservationsList?.length > 0 && (
                  <>
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                      {`${ownerReservationsList?.length} bookings`}
                    </span>
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                      {`${ownerRoomNightsTotal} nights`}
                    </span>
                  </>
                )}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {ownerReservationsList?.length > 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {ownerReservationsList.map((reservation: any) => (
                      <OwnerReservation
                        reservation={reservation}
                        key={reservation.id}
                        handleReservations={handleReservations}
                        portalAllowReservation={portalAllowReservation}
                      />
                    ))}
                  </ul>
                ) : (
                  <EmptyState text="No Owner Reservations Found." />
                )}
              </dd>
            </div>

            {!!portalReservationDetails && (
              <div className="sm:col-span-2 mt-10">
                <dt className="text-sm font-medium text-gray-500 mb-2">
                  <span>Guest Reservations</span>

                  <span className="block md:inline-block">
                    <span className="ml-0 md:ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                      {`${guestReservationsList?.length} bookings`}
                    </span>
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                      {`${guestRoomNightsTotal} nights`}
                    </span>
                    {portalReservationDetails === "value" && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                        Total: {displayAmount(guestReservationsTotal)}
                      </span>
                    )}
                  </span>
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {guestReservationsList?.length > 0 ? (
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {guestReservationsList.map((reservation: any) => (
                        <GuestReservation
                          reservation={reservation}
                          key={reservation.id}
                          portalReservationDetails={portalReservationDetails}
                        />
                      ))}
                    </ul>
                  ) : (
                    <EmptyState text="No Guest Reservations Found." />
                  )}
                </dd>
              </div>
            )}
          </div>
        </div>
      </div>
    </dl>
  );
};

const ReservationTable = ({
  reservations,
  account,
}: {
  reservations: any[];
  account: any;
}) => {
  const [showOwnerOnly, setShowOwnerOnly] = useState(false);
  const [showHistoryBookings, setShowHistoryBookings] = useState(false);
  const {
    portalAvailability = 0,
    roomAvailability,
    portalAllowReservation,
    ownerReservations,
    portalReservationDetails,
  } = account || {};

  const reservationsFiltered = reservations.filter((reservation) => {
    const { agent, checkOut } = reservation;
    const isOwnerMatch =
      showOwnerOnly || !portalReservationDetails ? agent === "OWNER" : true;
    const isDateMatch = showHistoryBookings || dayjs(checkOut).isAfter(dayjs());
    return isOwnerMatch && isDateMatch;
  });

  const roomNightsTotal = reservationsFiltered.reduce(
    (sum: number, res: any) =>
      (sum += dayjs(res.checkOut).diff(res.checkIn, "days") || 0),
    0
  );

  const reservationsTotal = reservationsFiltered.reduce(
    (sum: number, res: any) => (sum += +res.accommodationTotal || 0),
    0
  );

  return (
    <div className="px-0">
      <div className="flex flex-col gap-y-4 md:flex-row justify-between mb-4">
        <div>
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            {`${reservationsFiltered?.length} bookings`}
          </span>
          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            {`${roomNightsTotal} nights`}
          </span>
          {portalReservationDetails === "value" && (
            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              Total: {displayAmount(reservationsTotal)}
            </span>
          )}
        </div>

        <div>
          {!!portalReservationDetails && (
            <BooleanSwitch
              checked={showOwnerOnly}
              onChange={() => setShowOwnerOnly(!showOwnerOnly)}
              label={"Show owner bookings only"}
            />
          )}
          <BooleanSwitch
            className={"mt-2"}
            checked={showHistoryBookings}
            onChange={() => setShowHistoryBookings(!showHistoryBookings)}
            label={"Show history bookings"}
          />
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Guest
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Stay
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Channel
            </th>
            {portalReservationDetails === "value" && (
              <th
                scope="col"
                className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right"
              >
                Total
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white">
          {reservationsFiltered.map((reservation, personIdx) => {
            const {
              id,
              firstName,
              lastName,
              adults,
              children,
              ref,
              checkIn,
              checkOut,
              agent,
              agentName,
              accommodationTotal,
            } = reservation;

            const los = dayjs(checkOut).diff(checkIn, "days");

            const initial = `${String(firstName || "")[0] || ""}${
              String(lastName || "")[0] || ""
            }`;
            const isOwner = agent === "OWNER";

            return (
              <tr
                key={id}
                className={
                  isOwner
                    ? "bg-green-50"
                    : personIdx % 2 === 0
                    ? undefined
                    : "bg-gray-50"
                }
              >
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                    {ref}
                  </span>
                  <span className="font-medium">{initial}</span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm ">
                  <span>{`${displayDate(checkIn)} ~ ${displayDate(
                    checkOut
                  )}`}</span>
                  <span className="text-gray-500 ml-1">{`(${los}N)`}</span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  {agentName || agent}
                </td>{" "}
                {portalReservationDetails === "value" && (
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                    {displayAmount(accommodationTotal)}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const MonthAvailability = ({
  roomId,
  availability,
  monthCount,
}: {
  roomId: string;
  availability: any;
  monthCount: number;
}) => {
  const monthDays = Array(31)
    .fill("days")
    .map((_, index) => (
      <div
        style={{ width: "25px", height: "25px", minWidth: "25px" }}
        className="border border-light text-center flex justify-center items-center"
        key={`month-${index}`}
      >
        {index + 1}
      </div>
    ));

  const today = dayjs().format("YYYY-MM-DD");
  const startOfThisMonth = dayjs(today).startOf("months").format("YYYY-MM-DD");

  const avMonth = Array(+monthCount)
    .fill("month")
    .map((_, indexMonth) => {
      const month = dayjs(startOfThisMonth)
        .add(indexMonth, "months")
        .format("YYYY-MM-DD");

      const monthLength = Number(dayjs(month).endOf("month").format("DD"));

      const daysInMonth = Array(monthLength)
        .fill("days")
        .map((_, indexDay) => {
          const date = dayjs(month).add(indexDay, "days").format("YYYY-MM-DD");
          const isPast = date < today;
          const isAvailable =
            availability?.[date]?.[roomId]?.isAvailable !== false;

          return (
            <div
              key={`AV-${date}`}
              style={{ width: "25px", height: "25px", minWidth: "25px" }}
              className={`rounded border border-light text-center flex-center ${
                isPast ? "bg-gray-500" : isAvailable ? "bg-white" : "bg-red-500"
              }`}
            ></div>
          );
        });

      return (
        <div className="flex text-xs" key={`MONTH-${month}`}>
          <div
            className="border border-light text-center flex items-center pl-2 font-weight-bold"
            style={{ width: "100px", minWidth: "100px" }}
          >
            {dayjs(month).format("MMM YY")}
          </div>
          {daysInMonth}
        </div>
      );
    });

  return (
    <div className="overflow-x-scroll">
      <div className="flex text-xs font-weight-bold">
        <div
          className="border border-light text-center flex-center justify-content-start pl-2"
          style={{ width: "100px", minWidth: "100px" }}
        >
          Month
        </div>
        {monthDays}
      </div>
      {avMonth.map((item) => item)}
    </div>
  );
};
